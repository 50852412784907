


import 'bootstrap'
import 'slick-carousel'
import 'jquery'
import 'cookieconsent'

import  '@fortawesome/fontawesome-free'

import  './parallax/parallax.min.js'


$(document).ready(function(){ 


    $('.joinlist').bind('click', function(){ 
      $('#joinlisttitle').addClass('pulsate'); 
    }); 

    $('.slick-carousel').slick(
    {
    autoplay: true, 
    autoplaySpeed: 1300, 
    dots: false,
    arrows: false,
    speed: 1000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }
	]
	});
});

var GTMID="G-M3WQQ0374M";
var popup;
var hitItCrows;

window.addEventListener('load', function(){
console.log("loaded");


  window.cookieconsent.initialise({
   //set revokeBtn if you don't want to see a tiny pullup bar overlapping your website
   //if revokeBtn is set, make sure to include a link to cookie settings in your footer
   //you can open your banner again with: popup.open();
   revokeBtn: "<div class='cc-revoke'></div>",
   position: "bottom", 
   type: "opt-in",
   theme: "black",
   palette: {
       popup: {
           background: "#333f48",
           text: "#fff"
        },
       button: {
           background: "#fff",
           text: "#33aebc"
        }
    },
    onInitialise: function(status) {
      // request gtag.js on page-load when the client already consented
      if(status == cookieconsent.status.allow) setCookies();
    },
    onStatusChange: function(status) {
      // resquest gtag cookies on a new consent
      if (this.hasConsented()) setCookies();
      else deleteCookies(this.options.cookie.name)
    },
  function (p) {
        popup = p;
  	}

});	
});

//it is absolutely crucial to define gtag in the global scope
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', GTMID, {'anonymize_ip': true});

function setCookies() {
    var s = document.createElement('script');
    s.type = "text/javascript"
    s.async = "true";
		s.src="https://www.googletagmanager.com/gtag/js?id=" + GTMID; 
    var x = document.getElementsByTagName('script')[0];
    /*
    x.parentNode.insertBefore(s, x);
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', GTMID);
      */
};

function deleteCookies(cookieconsent_name) {
        var keep = [cookieconsent_name, "DYNSRV"];

        document.cookie.split(';').forEach(function(c) {
            c = c.split('=')[0].trim();
            if (!~keep.indexOf(c))
                document.cookie = c + '=;' + 'expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/';
        });
};



